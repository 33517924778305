<template>
  <div>
    <v-card>
      <v-card-title>User Data Access</v-card-title>
      <v-card-text>
        <user-access-area :username="username" ref="refUserAccessArea"></user-access-area>
      </v-card-text>
      <v-divider class="pb-3"></v-divider>
      <v-card-text>
        <user-access-department :username="username" ref="refUserAccessDepartment"></user-access-department>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import UserAccessArea from './UserAccessArea.vue'
import UserAccessDepartment from './UserAccessDepartment.vue'
export default {
  components: { UserAccessArea, UserAccessDepartment },
  props: ["username"],
  data() {
    return {
      dataDetail: {},
      loading: false,
    }
  },
}
</script>