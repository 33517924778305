<template>
  <v-card outlined class="mb-1" :loading="loading">
    <v-form v-model="valid" lazy-validation ref="form1">
      <v-toolbar elevation="0">
        <v-toolbar-title>General</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn color="primary" icon @click="getDetail(true)" :loading="loading">
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
        <v-btn color="primary" icon v-if="!editGeneral" @click.stop="editGeneral = true">
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
        <v-btn color="primary" icon v-if="editGeneral" @click.stop="editGeneral = false; save()">
          <v-icon>mdi-content-save</v-icon>
        </v-btn>
        <v-btn color="error" icon v-if="editGeneral" @click.stop="editGeneral = false; getDetail()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field v-model="dataDetail.user_type" dense placeholder="User Type" label="User Type" disabled readonly filled outlined></v-text-field>
            <v-text-field v-model="dataDetail.username" dense placeholder="Username" label="Username" disabled readonly filled outlined></v-text-field>
            <v-text-field v-model="dataDetail.first_name" :rules="formRules.first_name" dense placeholder="First Name" label="First Name" :disable="!editGeneral" :readonly="!editGeneral" :filled="!editGeneral" outlined></v-text-field>
            <v-text-field v-model="dataDetail.last_name" dense placeholder="Last Name" label="Last Name" :disable="!editGeneral" :readonly="!editGeneral" :filled="!editGeneral" outlined></v-text-field>
            <v-text-field v-model="dataDetail.area" dense placeholder="Area" label="Area" :disable="!editGeneral" :readonly="!editGeneral" :filled="!editGeneral" outlined></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field :rules="formRules.email" v-model="dataDetail.email" dense placeholder="Email" label="Email" :disable="!editGeneral" :readonly="!editGeneral" :filled="!editGeneral" outlined></v-text-field>
            <v-text-field :error-messages="formErrors.phone" v-model="dataDetail.phone" dense placeholder="Phone" label="Phone" :disable="!editGeneral" :readonly="!editGeneral" :filled="!editGeneral" outlined></v-text-field>
            <v-text-field :error-messages="formErrors.employee_position" v-model="dataDetail.employee_position" dense placeholder="Position" label="Position" :disable="!editGeneral" :readonly="!editGeneral" :filled="!editGeneral" outlined></v-text-field>
            <v-select v-model="dataDetail.is_active" label="Active" item-text="label" item-value="value" :items="[{ label: 'Yes', value: true }, { label: 'No', value: false }]" dense :disable="!editGeneral" :readonly="!editGeneral" :filled="!editGeneral" outlined></v-select>
            <v-autocomplete v-model="dataDetail.employee" :items="employees" :search-input.sync="searchEmployee" :loading="loadingEmployees" item-text="full_name" item-value="id" label="Employee (for Link)" outlined dense auto-select-first return-object :disable="!editGeneral" :readonly="!editGeneral" :filled="!editGeneral"></v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6">
            <v-radio-group v-model="dataDetail.user_type" :rules="formRules.user_type" :disable="!editGeneral" :readonly="!editGeneral" :filled="!editGeneral">
              <template v-slot:label>
                <div>User Type</div>
              </template>
              <v-radio value="admin" :disable="!editGeneral" :readonly="!editGeneral" :filled="!editGeneral">
                <template v-slot:label>
                  <div>Admin</div>
                </template>
              </v-radio>
              <v-radio value="user" :disable="!editGeneral" :readonly="!editGeneral" :filled="!editGeneral">
                <template v-slot:label>
                  <div>User</div>
                </template>
              </v-radio>
              <v-radio value="customer" :disable="!editGeneral" :readonly="!editGeneral" :filled="!editGeneral">
                <template v-slot:label>
                  <div>Customer</div>
                </template>
              </v-radio>
            </v-radio-group>

            <v-radio-group v-model="dataDetail.user_level" :rules="formRules.user_type" :disable="!editGeneral" :readonly="!editGeneral" :filled="!editGeneral">
              <template v-slot:label>
                <div>User Level</div>
              </template>
              <v-radio value="sa" :disable="!editGeneral" :readonly="!editGeneral" :filled="!editGeneral">
                <template v-slot:label>
                  <div>System Administrator</div>
                </template>
              </v-radio>
              <v-radio value="admin" :disable="!editGeneral" :readonly="!editGeneral" :filled="!editGeneral">
                <template v-slot:label>
                  <div>Standar Admin</div>
                </template>
              </v-radio>
              <v-radio value="standard" :disable="!editGeneral" :readonly="!editGeneral" :filled="!editGeneral">
                <template v-slot:label>
                  <div>Standar User</div>
                </template>
              </v-radio>
              <v-radio value="sales" :disable="!editGeneral" :readonly="!editGeneral" :filled="!editGeneral">
                <template v-slot:label>
                  <div>Sales</div>
                </template>
              </v-radio>
              <v-radio value="scm" :disable="!editGeneral" :readonly="!editGeneral" :filled="!editGeneral">
                <template v-slot:label>
                  <div>S C M</div>
                </template>
              </v-radio>
              <v-radio value="wh" :disable="!editGeneral" :readonly="!editGeneral" :filled="!editGeneral">
                <template v-slot:label>
                  <div>Warehouse</div>
                </template>
              </v-radio>
              <v-radio value="area" :disable="!editGeneral" :readonly="!editGeneral" :filled="!editGeneral">
                <template v-slot:label>
                  <div>Area</div>
                </template>
              </v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
      </v-card-text>
    </v-form>
  </v-card>
</template>

<script>
export default {
  props: {
    username: String
  },
  data() {
    return {
      valid: false,
      editGeneral: false,
      loading: null,
      dataDetail: {
        id: null,
        username: null,
        email: null,
        first_name: null,
        last_name: null,
        user_type: null,
        user_level: null,
        user_img: null,
        last_login_date: null,
        last_login_ip_address: null,
        area: null,
        phone: null,
        employee_position: null,
        is_active: null,
        must_change_password: null,
        employee_id: null,
        employee: {
          id: 254,
          full_name: 'Apri',
          emp_id: 'akjsdfblds',
        },
      },
      formRules: {
        first_name: [(v) => !!v || "First Name is required"],
        user_type: [(v) => !!v || "User Type is required"],
        email: [
          (v) => !!v || "E-mail is required",
          (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
        ],
      },
      formErrors: {},
      tmp_dataDetail: {},
      employees: [],
      loadingEmployees: false,
      searchEmployee: null,
    };
  },

  watch: {
    // eslint-disable-next-line no-unused-vars
    async searchEmployee(val) {
      this.loadingEmployees = true;
      let params = {
        search: val
      }
      await this.$axios
        .get("user/get-employees", {
          params: params,
          cacheConfig: false,
        })
        .then((res) => {
          var resData = res.data;
          this.loadingEmployees = false;
          if (resData.status == "success") {
            this.employees = resData.data;
          } else {
            this.showAlert(resData.status, resData.message);
          }

          this.showLoadingOverlay(false);
          this.loadingEmployees = false;
        })
        .catch((error) => {
          this.showLoadingOverlay(false);
          this.loadingEmployees = false;
          this.axiosErrorHandler(error);
        });
    },
  },

  methods: {
    async getDetail() {
      this.loading = true;

      var username = this.username;

      this.showLoadingOverlay(true);
      var params = {
        username: username,
      };
      await this.$axios
        .get("user/data-detail", {
          params: params,
          cacheConfig: false,
        })
        .then((res) => {
          var resData = res.data;
          this.loading = false;
          if (resData.status == "success") {
            this.dataDetail.id = resData.data.user.id;
            this.dataDetail.username = resData.data.user.username;
            this.dataDetail.email = resData.data.user.email;
            this.dataDetail.first_name = resData.data.user.first_name;
            this.dataDetail.last_name = resData.data.user.last_name;
            this.dataDetail.user_type = resData.data.user.user_type;
            this.dataDetail.user_level = resData.data.user.user_level;
            this.dataDetail.user_img = resData.data.user.user_img;
            this.dataDetail.last_login_date = resData.data.user.last_login_date;
            this.dataDetail.last_login_ip_address = resData.data.user.last_login_ip_address;
            this.dataDetail.area = resData.data.user.area;
            this.dataDetail.phone = resData.data.user.phone;
            this.dataDetail.employee_position = resData.data.user.employee_position;
            this.dataDetail.is_active = resData.data.user.is_active;
            this.dataDetail.must_change_password = resData.data.user.must_change_password;
            this.dataDetail.employee_id = resData.data.user.employee_id;
            this.dataDetail.employee = resData.data.user.employee;

            this.employees.push(this.dataDetail.employee)
          } else {
            this.showAlert(resData.status, resData.message);
          }

          this.showLoadingOverlay(false);
          this.loading = false;
        })
        .catch((error) => {
          this.showLoadingOverlay(false);
          this.loading = false;
          this.axiosErrorHandler(error);
        });
    },
    async save() {
      var d = this.$refs.form1.validate();

      if (d == true) {
        this.showLoadingOverlay(true);
        var formData = new FormData();
        formData.append("username", this.dataDetail.username);
        formData.append("first_name", this.dataDetail.first_name);
        formData.append("last_name", this.dataDetail.last_name);
        formData.append("user_level", this.dataDetail.user_level);
        formData.append("email", this.dataDetail.email);
        formData.append("area", this.dataDetail.area);
        formData.append("phone", this.dataDetail.phone);
        formData.append("user_type", this.dataDetail.user_type);
        formData.append("employee_position", this.dataDetail.employee_position);
        formData.append("is_active", this.dataDetail.is_active);
        if (typeof this.dataDetail.employee !== 'undefined') {
          formData.append("employee_id", this.dataDetail.employee.id);
        }
        await this.$axios
          .post("user/update", formData)
          .then((res) => {
            this.showLoadingOverlay(false);
            var resData = res.data;
            if (resData.status == "success") {
              this.showAlert(resData.status, resData.message);
              this.getDetail(true);
            } else {
              this.editGeneral = true;
              this.formErrors = resData.data.errors;
              this.showAlert(resData.status, resData.message);
            }
          })
          .catch((error) => {
            this.showLoadingOverlay(false);
            this.axiosErrorHandler(error);
          });
      }
    },
  },

  mounted() {
    this.getDetail();
  },
};
</script>