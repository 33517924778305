<template>
  <v-card outlined class="mb-1" :loading="loading">
    <v-form v-model="valid" lazy-validation ref="form1">
      <v-toolbar elevation="0" dense>
        <v-toolbar-title>Change Password</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn small color="primary" icon v-if="!editPassword" @click="editPassword = true; minimize = false">
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
        <v-btn small color="primary" icon v-if="editPassword" @click="editPassword = false; updatePassword()">
          <v-icon>mdi-content-save</v-icon>
        </v-btn>
        <v-btn small color="error" icon v-if="editPassword" @click="editPassword = false; getDetail()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-btn small color="primary" icon @click="minimize = !minimize">
          <v-icon v-if="minimize">mdi-chevron-down</v-icon>
          <v-icon v-if="!minimize">mdi-chevron-up</v-icon>
        </v-btn>
      </v-toolbar>
      <v-expand-transition>
        <v-card-text v-show="!minimize">
          <v-text-field :error-messages="formErrors.password" :rules="formRules.password" v-model="password" type="password" dense placeholder="New Password" label="New Password" :disabled="!editPassword" :filled="!editPassword" outlined></v-text-field>
          <v-text-field :error-messages="formErrors.password_conf" :rules="formRules.password_conf" v-model="password_conf" type="password" dense placeholder="Confirm Password" label="Confirm Password" :disabled="!editPassword" :filled="!editPassword" outlined></v-text-field>
          <v-select v-model="userData.must_change_password" item-text="label" item-value="value" :items="must_change_password_items" :disabled="!editPassword" label="Must Change Password" placeholder="Must Change Password" dense outlined></v-select>
        </v-card-text>
      </v-expand-transition>
    </v-form>
  </v-card>
</template>

<script>
export default {
  props: {
    username: String,
  },
  data() {
    return {
      valid: null,
      userData: {},
      formRules: {
        password: [(v) => !!v || "Password is required"],
        password_conf: [(v) => !!v || "Password Conf. is required"],
      },
      formErrors: {},
      editPassword: false,
      password: "",
      password_conf: "",
      loading: null,
      minimize: true,
      must_change_password: null,
      must_change_password_items: [
        { label: 'Yes', value: true },
        { label: "No", value: false }
      ]
    };
  },

  watch: {
    // eslint-disable-next-line no-unused-vars
    editPassword(n, o) {
      if (n == true) {
        this.formErrors = {}
      }
    }
  },

  methods: {
    async getDetail(refresh) {
      this.loading = true;

      if (refresh)
        this.AxiosStorageRemove('GET', 'user/data-detail');

      var username = this.username;

      this.showLoadingOverlay(true);
      var params = {
        username: username,
      };
      await this.$axios
        .get("user/data-detail", {
          params: params,
          cacheConfig: true,
        })
        .then((res) => {
          var resData = res.data;
          this.loading = false;
          if (resData.status == "success") {
            this.userData = resData.data.user;
          } else {
            this.showAlert(resData.status, resData.message);
          }

          this.showLoadingOverlay(false);
          this.loading = false;
        })
        .catch((error) => {
          this.showLoadingOverlay(false);
          this.loading = false;
          this.axiosErrorHandler(error);
        });
    },
    async updatePassword() {
      var d = this.$refs.form1.validate();

      if (d == true) {
        this.loading = true;
        var formData = new FormData();
        formData.append("username", this.userData.username);
        formData.append("password", this.password);
        formData.append("password_conf", this.password_conf);
        formData.append("must_change_password", this.userData.must_change_password ? 1 : 0);
        await this.$axios
          .post("user/update_password", formData)
          .then((res) => {
            this.loading = false;
            var resData = res.data;
            if (resData.status == "success") {
              this.showAlert(resData.status, resData.message);
              this.getDetail(true);
            } else {
              this.showAlert(resData.status, resData.message);
            }

            this.password = ''
            this.password_conf = ''
          })
          .catch((error) => {
            this.loading = false;
            this.password = ''
            this.password_conf = ''
            this.axiosErrorHandler(error);
          });

        this.$refs.form1.reset();
      }
    },
  },
  mounted() {
    this.getDetail();
  },
};
</script>