<template>
  <div>
    <page-toolbar title="Edit User"></page-toolbar>

    <v-container>
      <page-title :title="$route.query.u"></page-title>

      <!-- <v-row> -->
      <!-- <v-col cols="12" class="d-flex"> -->
      <!-- <v-btn class="mr-1" @click.stop="goBack()">Back</v-btn> -->
      <!-- <v-btn class="mr-1" @click.stop="getDetail()">Refresh</v-btn> -->
      <!-- <v-btn class="mr-1" color="primary" @click.stop="save()">Save</v-btn> -->
      <!-- </v-col> -->
      <!-- </v-row> -->

      <v-row>
        <v-col cols="12" md="9">
          <v-sheet class="mb-3" outlined>
            <v-tabs>
              <v-tab @click.stop="tabComponent = 'UserUpdateGeneral'" :username="$route.query.u">General</v-tab>
              <!-- <v-tab @click.stop="tabComponent='UserUpdateMenu'" :username="$route.query.u">Menu</v-tab> -->
              <v-tab @click.stop="tabComponent = 'UserUpdateModule'" :username="$route.query.u">Permission</v-tab>
              <v-tab @click.stop="tabComponent = 'UserAccess'" :username="$route.query.u">Data Access</v-tab>
            </v-tabs>
          </v-sheet>
          <v-slide-x-transition>
            <UserUpdateGeneral ref="refUserUpdateGeneral" :username="$route.query.u" v-show="tabComponent == 'UserUpdateGeneral'"></UserUpdateGeneral>
          </v-slide-x-transition>
          <!-- <v-slide-x-transition>
            <UserUpdateMenu ref="refUserUpdateMenu" :username="$route.query.u" v-show="tabComponent=='UserUpdateMenu'"></UserUpdateMenu>
          </v-slide-x-transition> -->
          <v-slide-x-transition>
            <UserUpdateModule ref="refUserUpdateModule" :username="$route.query.u" v-show="tabComponent == 'UserUpdateModule'"></UserUpdateModule>
          </v-slide-x-transition>
          <v-slide-x-transition>
            <UserAccess ref="refUserAccess" :username="$route.query.u" v-show="tabComponent == 'UserAccess'"></UserAccess>
          </v-slide-x-transition>
        </v-col>
        <v-col cols="12" md="3">
          <UserUpdateImage ref="refUserUpdateImage" :username="$route.query.u"></UserUpdateImage>
          <UserUpdatePassword ref="refUserUpdatePassword" :username="$route.query.u"></UserUpdatePassword>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import UserUpdateGeneral from "@/components/user/UserUpdateGeneral.vue";
// import UserUpdateMenu from "@/components/user/UserUpdateMenu.vue";
import UserUpdateModule from "@/components/user/UserUpdateModule.vue";
import UserUpdateImage from "@/components/user/UserUpdateImage.vue";
import UserUpdatePassword from "@/components/user/UserUpdatePassword.vue";
import UserAccess from "./components/UserAccess.vue";
export default {
  components: {
    UserUpdateGeneral,
    // UserUpdateMenu,
    UserUpdateModule,
    UserUpdateImage,
    UserUpdatePassword,
    UserAccess,
  },
  data() {
    return {
      tabComponent: "UserUpdateGeneral",
    };
  },

  methods: {},

  mounted() { },
};
</script>