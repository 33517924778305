<template>
  <div>
    <v-card outlined class="mb-1" :loading="loading">
      <v-toolbar elevation="0" dense>
        <v-toolbar-title>Image</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn small color="primary" icon v-if="!editImage" @click="editImage = true; minimize = false">
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
        <v-btn small color="primary" icon v-if="editImage" @click="$refs.fileupload.click()">
          <v-icon>mdi-upload</v-icon>
        </v-btn>
        <v-btn small color="error" icon v-if="editImage" @click="editImage = false; getDetail()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-btn small color="primary" icon @click="minimize = !minimize">
          <v-icon v-if="minimize">mdi-chevron-down</v-icon>
          <v-icon v-if="!minimize">mdi-chevron-up</v-icon>
        </v-btn>
      </v-toolbar>
      <v-expand-transition>
        <v-card-text v-show="!minimize">
          <v-sheet>
            <div class="d-flex justify-center mb-2">
              <v-sheet width="100%">
                <v-img :src="userData.user_img"></v-img>
              </v-sheet>
            </div>
            <v-btn color="green" :disabled="!editImage" block dark @click="$refs.fileupload.click()">Change Image</v-btn>
            <input type="file" ref="fileupload" name="fileupload" style="display:none;" v-on:change="changeImage" />
          </v-sheet>
        </v-card-text>
      </v-expand-transition>
    </v-card>
  </div>
</template>


<script>
export default {
  props: {
    username: String,
  },
  data() {
    return {
      userData: {},
      editImage: false,
      loading: null,
      minimize: false,
    };
  },

  methods: {
    async getDetail(refresh) {
      this.loading = true;

      if (refresh)
        this.AxiosStorageRemove('GET', 'user/data-detail');

      var username = this.username;

      this.showLoadingOverlay(true);
      var params = {
        username: username,
      };
      await this.$axios
        .get("user/data-detail", {
          params: params,
          cacheConfig: true,
        })
        .then((res) => {
          var resData = res.data;
          this.loading = false;
          if (resData.status == "success") {
            this.userData = resData.data.user;
          } else {
            this.showAlert(resData.status, resData.message);
          }

          this.showLoadingOverlay(false);
          this.loading = false;
        })
        .catch((error) => {
          this.showLoadingOverlay(false);
          this.loading = false;
          this.axiosErrorHandler(error);
        });
    },
    async changeImage() {
      this.loading = true;
      this.fileupload = this.$refs.fileupload.files[0];

      if (!confirm("Upload File?")) return;

      this.showLoadingOverlay(true);

      let formData = new FormData();
      formData.append("user_img", this.fileupload);
      formData.append("username", this.userData.username);
      await this.$axios
        .post("user/update-image", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          this.loading = false;
          this.showLoadingOverlay(false);
          var resData = res.data;
          if (resData.status == "success") {
            this.showAlert(resData.status, resData.message);

            this.getDetail(true);
          } else {
            this.showAlert(resData.status, resData.message);
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error) => {
          this.loading = false;
          this.showLoadingOverlay(false);
          this.axiosErrorHandler(error);
        });
    },
  },

  mounted() {
    this.getDetail();
  },
};
</script>

<style scoped>
.minimize {
  height: 0px !important;
  overflow-y: hidden !important;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
</style>