<template>
  <div>
    <h3>By Area</h3>
    <div class="mx-n4">
      <v-data-table dense :loading="datatable.loading" :search="datatable.search" item-key="area_code" :headers="datatable.columns" :items="datatable.data" :items-per-page="5" class="elevation-0">
        <template v-slot:top>
          <v-toolbar elevation="0">
            <v-btn color="primary" icon text @click="showDialogAdd()">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
            <v-spacer></v-spacer>

            <v-text-field v-model="datatable.search" rounded outlined dense hide-details style="max-width: 200px" label="search" placeholder="search" append-icon="mdi-magnify"></v-text-field>

            <v-btn text icon @click="getData(true)">
              <v-icon>mdi-sync</v-icon>
            </v-btn>
          </v-toolbar>
        </template>
        <template v-slot:[`item.id`]="{ item }">
          <v-btn icon text small color="error" @click="deleteData(item)">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </div>

    <v-dialog v-model="dialog1" max-width="400">
      <v-card>
        <v-toolbar elevation="0" class="text-h5 grey lighten-2">
          <v-toolbar-title>Add Area</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon text small @click="closeDialogAdd()"><v-icon>mdi-close</v-icon></v-btn>
        </v-toolbar>
        <v-divider></v-divider>
        <v-card-text style="max-height: 400px;overflow-y: auto;" class="px-0">
          <v-list dense>
            <v-list-item-group v-model="selectedArea" color="primary">
              <v-list-item v-for="(item, i) in $store.state.master.area" :key="i">
                <v-list-item-content>
                  <v-list-item-title>{{ item.area_code }}</v-list-item-title>
                  <v-list-item-subtitle>{{ item.area_name }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="primary" @click="saveData()" :loading="loading">Add</v-btn>
          <v-btn color="warning" @click="saveDataAll()" :loading="loading">Add All</v-btn>
          <v-spacer></v-spacer>
          <v-btn @click="closeDialogAdd()">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  props: ["username"],
  data() {
    return {
      loading: false,
      datatable: {
        loading: false,
        search: "",
        columns: [
          { text: '#', value: 'id' },
          { text: 'Area Code', value: 'area_code' },
          { text: 'Area Name', value: 'area_name' },
          { text: 'Regional Code', value: 'regional_code' },
          { text: 'Regional Name', value: 'regional_name' },
        ],
        data: []
      },
      dialog1: false,
      selectedArea: null,
    }
  },
  methods: {
    async getData(refresh = false) {
      const uri = 'user/user-data-access/data/area';
      const params = {
        username: this.username
      }

      if (refresh == true) this.AxiosStorageRemove("GET", uri);

      this.datatable.loading = true;
      await this.$axios
        .get(uri, {
          params: params,
          cacheConfig: !refresh,
        })
        .then((res) => {
          var resData = res.data;
          if (resData.status == "success") {
            this.datatable.data = resData.data;
          } else {
            this.showAlert(resData.status, resData.message);
          }
          this.datatable.loading = false;
        })
        .catch((error) => {
          this.datatable.loading = false;
          this.axiosErrorHandler(error);
        });

    },
    async saveData() {
      this.loading = true;
      const uri = 'user/user-data-access/save/area';

      const listArea = this.$store.state.master.area;

      if (typeof listArea[this.selectedArea] == 'undefined') {
        this.showAlert("error", "Invalid data index!");
      }

      const selectedArea = listArea[this.selectedArea];

      var formData = new FormData();
      formData.append("area_code", selectedArea.area_code);
      formData.append("username", this.username);

      await this.$axios
        .post(uri, formData)
        .then((res) => {
          this.loading = false;
          var resData = res.data;
          if (resData.status == "success") {
            this.showAlert(resData.status, resData.message);
            this.closeDialogAdd();
            this.getData(true);
          } else {
            this.showAlert(resData.status, resData.message);
          }
        })
        .catch((error) => {
          this.loading = false;
          this.axiosErrorHandler(error);
        });
    },
    async saveDataAll() {
      this.loading = true;
      const uri = 'user/user-data-access/save/area/all';

      var formData = new FormData();
      formData.append("username", this.username);

      await this.$axios
        .post(uri, formData)
        .then((res) => {
          this.loading = false;
          var resData = res.data;
          if (resData.status == "success") {
            this.showAlert(resData.status, resData.message);
            this.closeDialogAdd();
            this.getData(true);
          } else {
            this.showAlert(resData.status, resData.message);
          }
        })
        .catch((error) => {
          this.loading = false;
          this.axiosErrorHandler(error);
        });
    },
    deleteData(item = null) {
      const uri = 'user/user-data-access/delete';
      const post = async () => {
        const params = {
          id: item.id,
        }
        await this.$axios
          .delete(uri, {
            params: params
          })
          .then((res) => {
            this.loading = false;
            var resData = res.data;
            if (resData.status == "success") {
              this.showAlert(resData.status, resData.message);
              this.getData(true);
            } else {
              this.showAlert(resData.status, resData.message);
            }
          })
          .catch((error) => {
            this.loading = false;
            this.axiosErrorHandler(error);
          });
      }

      this.showConfirm("Confirm", "Delete Data?", post);
    },
    showDialogAdd() {
      this.dialog1 = true;
    },
    closeDialogAdd() {
      this.dialog1 = false;
    }
  },
  mounted() {
    this.getData(true);
  }
}
</script>